import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Speech } from "hooks";

export type UseCreateSpeechResponse = GeneralResponse<number>;

export type UseCreateSpeechVariables = Omit<
  Speech,
  "id" | "isVisible" | "isVisibleMain" | "publishDate" | "readCount"
> &
  Partial<Pick<Speech, "publishDate">>;

export function useCreateSpeech(
  props?: MutationParams<UseCreateSpeechResponse, unknown, UseCreateSpeechVariables>
) {
  return useAPIMutation<UseCreateSpeechResponse, unknown, UseCreateSpeechVariables>({
    ...props,
    url: "/api/leaders-speech/create",
    instance: generalRequest,
    method: "POST",
  });
}
