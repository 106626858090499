import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";
import { Languages } from "types";

export interface LanguageItem extends Omit<MenuItemType, "key"> {
  key: Languages;
}

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const storageLang = localStorage.getItem("i18nextLng") as Languages;

  const changeLang = (lang: Languages) => {
    i18n.changeLanguage(lang.toLowerCase());
  };

  const languageItems: Array<LanguageItem> = [
    {
      key: "lat",
      label: "O‘zbekcha",
    },
    {
      key: "ru",
      label: "Русский",
    },
    {
      key: "en",
      label: "English",
    },
  ];

  const currentLang = languageItems.find(({ key }) => key === storageLang);

  return { currentLang, languageItems, changeLang };
};
