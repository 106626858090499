import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Useful } from "hooks";

export type UseCreateUsefulResponse = GeneralResponse<number>;

export type UseCreateUsefulVariables = Omit<Useful, "id" | "ord" | "isVisible" | "url"> & Partial<Pick<Useful, "ord" | "url">>;

export function useCreateUseful(
  props?: MutationParams<UseCreateUsefulResponse, unknown, UseCreateUsefulVariables>
) {
  return useAPIMutation<UseCreateUsefulResponse, unknown, UseCreateUsefulVariables>({
    ...props,
    url: "/api/useful-page/create",
    instance: generalRequest,
    method: "POST",
  });
}
