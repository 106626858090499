import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { CooperationNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetCooperationByIdResponse = GeneralResponse<CooperationNullable>;

export function useGetCooperationById(id?: number | string, props?: QueryParams<UseGetCooperationByIdResponse>) {
  return useAPIQuery<UseGetCooperationByIdResponse>({
    ...props,
    url: `/api/cooperation/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}