import { forwardRef, useId } from 'react';
import WrapperInput from './WrapperInput';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { BaseInputProps } from '../Input';
import { TextAreaRef } from 'antd/es/input/TextArea';

export type InputTextAreaProps = TextAreaProps & BaseInputProps;

const TextArea = forwardRef<TextAreaRef, InputTextAreaProps>(({ label, ...props }, ref) => {
  const id = useId();
  const values = { label, id, size: props?.size, disabled: props?.disabled };

  return (
    <WrapperInput {...values}>
      <Input.TextArea id={id} {...props} ref={ref} />
    </WrapperInput>
  );
});

export default TextArea;
