import { forwardRef, useId } from 'react';
import WrapperInput from './WrapperInput';
import { Input } from 'antd';
import { InputRef, SearchProps } from 'antd/es/input';
import { BaseInputProps } from '../Input';

export type InputSearchProps = SearchProps & BaseInputProps;

const Search = forwardRef<InputRef, InputSearchProps>(({ label, ...props }, ref) => {
  const id = useId();
  const values = { label, id, size: props?.size, disabled: props?.disabled };

  return (
    <WrapperInput {...values}>
      <Input.Search id={id} {...props} ref={ref} />
    </WrapperInput>
  );
});

export default Search;
