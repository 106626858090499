import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Partner } from "hooks";

export type UseCreatePartnerResponse = GeneralResponse<number>;

export type UseCreatePartnerVariables = Omit<Partner, "id" | "isVisible" | "isVisibleMain" | "ord" | "iconGuid" | "url"> & Partial<Pick<Partner, "ord" | "iconGuid" | "url">>;

export function useCreatePartner(
  props?: MutationParams<UseCreatePartnerResponse, unknown, UseCreatePartnerVariables>
) {
  return useAPIMutation<UseCreatePartnerResponse, unknown, UseCreatePartnerVariables>({
    ...props,
    url: "/api/cooperation-item/create",
    instance: generalRequest,
    method: "POST",
  });
}
