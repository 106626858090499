import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { OfficialNullable } from "hooks";

export type UseGetOfficialAllResponse = GeneralResponse<GeneralData<OfficialNullable>>;

export function useGetOfficialAll(props?: QueryParams<UseGetOfficialAllResponse>) {
  return useAPIQuery<UseGetOfficialAllResponse>({
    ...props,
    url: "/api/official-person/get-all",
    instance: generalRequest,
    key: ["get-official-all", props?.params]
  });
}
