import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { StructureNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetStructureByIdResponse = GeneralResponse<StructureNullable>;

export function useGetStructureById(id?: number | string, props?: QueryParams<UseGetStructureByIdResponse>) {
  return useAPIQuery<UseGetStructureByIdResponse>({
    ...props,
    url: `/api/center-structure/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}