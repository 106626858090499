import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Tender } from "hooks";

export type UseCreateTenderResponse = GeneralResponse<number>;

export type UseCreateTenderVariables = Omit<
  Tender,
  "id" | "isVisible" | "isVisibleMain" | "status"
>;

export function useCreateTender(
  props?: MutationParams<UseCreateTenderResponse, unknown, UseCreateTenderVariables>
) {
  return useAPIMutation<UseCreateTenderResponse, unknown, UseCreateTenderVariables>({
    ...props,
    url: "/api/tender/create",
    instance: generalRequest,
    method: "POST",
  });
}
