import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { VideoReport } from "hooks";

export type UseCreateVideoReportResponse = GeneralResponse<number>;

export type UseCreateVideoReportVariables = Omit<
  VideoReport,
  "id" | "isVisible" | "isVisibleMain" | "readCount" | "publishDate"
> &
  Partial<Pick<VideoReport, "publishDate">>;

export function useCreateVideoReport(
  props?: MutationParams<
    UseCreateVideoReportResponse,
    unknown,
    UseCreateVideoReportVariables
  >
) {
  return useAPIMutation<
    UseCreateVideoReportResponse,
    unknown,
    UseCreateVideoReportVariables
  >({
    ...props,
    url: "/api/video/report/create",
    instance: generalRequest,
    method: "POST",
  });
}
