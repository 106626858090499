import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { VacancyNullable } from "hooks";

export type UseGetVacancyAllResponse = GeneralResponse<GeneralData<VacancyNullable>>;

export function useGetVacancyAll(props?: QueryParams<UseGetVacancyAllResponse>) {
  return useAPIQuery<UseGetVacancyAllResponse>({
    ...props,
    url: "/api/vacancy/get-all",
    instance: generalRequest,
  });
}
