import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { CorruptionFightNullable } from "hooks";

export type UseGetCorruptionFightAllResponse = GeneralResponse<GeneralData<CorruptionFightNullable>>;

export function useGetCorruptionFightAll(props?: QueryParams<UseGetCorruptionFightAllResponse>) {
  return useAPIQuery<UseGetCorruptionFightAllResponse>({
    ...props,
    url: "/api/fighting-corruption/get-all",
    instance: generalRequest,
  });
}
