import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Official, UseCreateOfficialVariables } from "hooks";

export type UseUpdateOfficialResponse = GeneralResponse<number>;

export type UseUpdateOfficialVariables =  Pick<Official, "id"> & UseCreateOfficialVariables;

export function useUpdateOfficial(
  props?: MutationParams<UseUpdateOfficialResponse, unknown, UseUpdateOfficialVariables>
) {
  return useAPIMutation<UseUpdateOfficialResponse, unknown, UseUpdateOfficialVariables>({
    ...props,
    url: "/api/official-person/update",
    instance: generalRequest,
    method: "PUT",
  });
}
