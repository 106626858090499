import { useCallback, useState } from "react";

export const useDialog = () => {
  const [id, setId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((id?: number) => {
    setIsOpen(true);
    id && setId(id);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setId(undefined);
  }, []);

  return { open, close, id, isOpen };
};
