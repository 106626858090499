import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteCorruptionFightResponse = GeneralResponse<boolean>;

export type UseDeleteCorruptionFightVariables = DeleteVariables;

export function useDeleteCorruptionFight(
  props?: MutationParams<UseDeleteCorruptionFightResponse, unknown, UseDeleteCorruptionFightVariables>
) {
  return useAPIMutation<UseDeleteCorruptionFightResponse, unknown, UseDeleteCorruptionFightVariables>({
    ...props,
    url: "/api/fighting-corruption/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
