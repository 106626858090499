import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { NewsNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetNewsByIdResponse = GeneralResponse<NewsNullable>;

export function useGetNewsById(id?: number | string, props?: QueryParams<UseGetNewsByIdResponse>) {
  return useAPIQuery<UseGetNewsByIdResponse>({
    ...props,
    url: `/api/news/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}