import { useTranslation } from "react-i18next";
import classes from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={classes["footer"]}>
      © 2024 {t('The International Strategic Centre for Agri-Food Development')}
    </footer>
  );
};

export default Footer;
