import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { CorruptionMaterial } from "hooks";

export type UseCreateCorruptionMaterialResponse = GeneralResponse<number>;

export type UseCreateCorruptionMaterialVariables = Omit<CorruptionMaterial, "id" | "isVisible" | "publishDate" | "url" | "guid"> & Partial<Pick<CorruptionMaterial, "publishDate" | "url" | "guid">>;

export function useCreateCorruptionMaterial(
  props?: MutationParams<UseCreateCorruptionMaterialResponse, unknown, UseCreateCorruptionMaterialVariables>
) {
  return useAPIMutation<UseCreateCorruptionMaterialResponse, unknown, UseCreateCorruptionMaterialVariables>({
    ...props,
    url: "/api/corruption-material/create",
    instance: generalRequest,
    method: "POST",
  });
}
