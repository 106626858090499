import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { MenuNullable } from "hooks";

export type UseGetMenuAllWithChildResponse = GeneralResponse<Array<MenuNullable>>;

export function useGetMenuAllWithChild(props?: QueryParams<UseGetMenuAllWithChildResponse>) {
  return useAPIQuery<UseGetMenuAllWithChildResponse>({
    ...props,
    url: "/api/menu/get-all-with-child",
    instance: generalRequest,
  });
}
