import { Button, ButtonProps, Upload, notification } from "antd";
import { MESSAGE_ERROR } from "constants/general";
import { useUploadFile } from "hooks";
import { FileType } from "components/shared/File";
import get from "lodash.get";
import { UploadOutlined } from "@ant-design/icons";

export type FileUploadProps = {
  add: (file: FileType) => void;
  accept?: string;
  error?: string;
  disabled?: boolean;
  button?: ButtonProps;
};

export function FileUpload({ add, accept, error, disabled, button }: FileUploadProps) {
  const uploadFileMutation = useUploadFile();

  return (
    <Upload
      disabled={disabled}
      accept={accept}
      customRequest={async (value) => {
        const { onSuccess, file } = value;

        const formData = new FormData();
        formData.append("file", file);

        await uploadFileMutation.mutateAsync(
          {
            payload: formData,
          },
          {
            onSuccess: (res) => {
              const guid = res.data;

              if (guid) {
                onSuccess?.("ok");
                add({
                  guid: guid,
                  name: get(file, "name"),
                });
              } else {
                notification.warning({ message: MESSAGE_ERROR });
              }
            },
            onError: () => notification.error({ message: MESSAGE_ERROR }),
          }
        );
      }}
      maxCount={1}
      showUploadList={false}
    >
      <Button type="dashed" icon={<UploadOutlined />} disabled={disabled || uploadFileMutation.isLoading} loading={uploadFileMutation.isLoading} {...button} />
      {error && <p className={"input-error"}>{error}</p>}
    </Upload>
  );
}

export default FileUpload;
