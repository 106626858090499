import { QueryParams, UserNullable, useAPIQuery } from "hooks";
import { generalRequest } from "services";

export type UseGetUserMeResponse = UserNullable;

export function useGetUserMe(props?: QueryParams<UseGetUserMeResponse>) {
  return useAPIQuery<UseGetUserMeResponse>({
    ...props,
    url: "/api/users/me",
    instance: generalRequest,
  });
}
