import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { News, UseCreateNewsVariables } from "hooks";

export type UseUpdateNewsResponse = GeneralResponse<number>;

export type UseUpdateNewsVariables = Pick<News, "id"> & UseCreateNewsVariables;

export function useUpdateNews(
  props?: MutationParams<UseUpdateNewsResponse, unknown, UseUpdateNewsVariables>
) {
  return useAPIMutation<UseUpdateNewsResponse, unknown, UseUpdateNewsVariables>({
    ...props,
    url: "/api/news/update",
    instance: generalRequest,
    method: "PUT",
  });
}
