import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Partner, UseCreatePartnerVariables } from "hooks";

export type UseUpdatePartnerResponse = GeneralResponse<number>;

export type UseUpdatePartnerVariables = Pick<Partner, "id"> & UseCreatePartnerVariables;

export function useUpdatePartner(
  props?: MutationParams<UseUpdatePartnerResponse, unknown, UseUpdatePartnerVariables>
) {
  return useAPIMutation<UseUpdatePartnerResponse, unknown, UseUpdatePartnerVariables>({
    ...props,
    url: "/api/cooperation-item/update",
    instance: generalRequest,
    method: "PUT",
  });
}
