import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeletePhotoReportResponse = GeneralResponse<boolean>;

export type UseDeletePhotoReportVariables = DeleteVariables;

export function useDeletePhotoReport(
  props?: MutationParams<UseDeletePhotoReportResponse, unknown, UseDeletePhotoReportVariables>
) {
  return useAPIMutation<UseDeletePhotoReportResponse, unknown, UseDeletePhotoReportVariables>({
    ...props,
    url: "/api/photo/report/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
