import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { AppealNullable } from "hooks";

export type UseGetAppealAllResponse = GeneralResponse<GeneralData<AppealNullable>>;

export function useGetAppealAll(props?: QueryParams<UseGetAppealAllResponse>) {
  return useAPIQuery<UseGetAppealAllResponse>({
    ...props,
    url: "/api/appeal/get-all",
    instance: generalRequest,
  });
}
