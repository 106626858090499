import classes from "./Logo.module.scss";
import { Link } from "react-router-dom";
import LogoImage from "assets/images/png/ISCAD_Logo.png";

const Logo = () => {

  return (
    <Link to={"/"} className={classes["wrapper"]}>
      <img
        src={LogoImage}
        alt="The International Strategic Centre for Agri-Food Development"
        loading="lazy"
        className={classes["image"]}
      />
    </Link>
  );
};

export default Logo;
