import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { BannerNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetBannerByIdResponse = GeneralResponse<BannerNullable>;

export function useGetBannerById(id?: number | string, props?: QueryParams<UseGetBannerByIdResponse>) {
  return useAPIQuery<UseGetBannerByIdResponse>({
    ...props,
    url: `/api/main-banner/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}