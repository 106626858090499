import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { VideoReport, UseCreateVideoReportVariables } from "hooks";

export type UseUpdateVideoReportResponse = GeneralResponse<number>;

export type UseUpdateVideoReportVariables = Pick<VideoReport, "id"> & UseCreateVideoReportVariables;

export function useUpdateVideoReport(
  props?: MutationParams<UseUpdateVideoReportResponse, unknown, UseUpdateVideoReportVariables>
) {
  return useAPIMutation<UseUpdateVideoReportResponse, unknown, UseUpdateVideoReportVariables>({
    ...props,
    url: "/api/video/report/update",
    instance: generalRequest,
    method: "PUT",
  });
}
