import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteBannerResponse = GeneralResponse<boolean>;

export type UseDeleteBannerVariables = DeleteVariables;

export function useDeleteBanner(
  props?: MutationParams<UseDeleteBannerResponse, unknown, UseDeleteBannerVariables>
) {
  return useAPIMutation<UseDeleteBannerResponse, unknown, UseDeleteBannerVariables>({
    ...props,
    url: "/api/main-banner/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
