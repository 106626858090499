import classes from "./Header.module.scss";
import { Flex, Row } from "antd";
import Language from "../components/Language";
import { Account, Logo } from "./components";

const Header = () => {
  return (
    <header className={classes.header}>
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Logo />

        <Flex gap={24}>
          <Account />
          <Language />
        </Flex>
      </Row>
    </header>
  );
};

export default Header;
