import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Event, UseCreateEventVariables } from "hooks";

export type UseUpdateEventResponse = GeneralResponse<number>;

export type UseUpdateEventVariables = Pick<Event, "id"> & UseCreateEventVariables;

export function useUpdateEvent(
  props?: MutationParams<UseUpdateEventResponse, unknown, UseUpdateEventVariables>
) {
  return useAPIMutation<UseUpdateEventResponse, unknown, UseUpdateEventVariables>({
    ...props,
    url: "/api/public-event/update",
    instance: generalRequest,
    method: "PUT",
  });
}
