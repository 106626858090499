import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteSpeechResponse = GeneralResponse<boolean>;

export type UseDeleteSpeechVariables = DeleteVariables;

export function useDeleteSpeech(
  props?: MutationParams<UseDeleteSpeechResponse, unknown, UseDeleteSpeechVariables>
) {
  return useAPIMutation<UseDeleteSpeechResponse, unknown, UseDeleteSpeechVariables>({
    ...props,
    url: "/api/leaders-speech/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
