import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Flex, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./Actions.module.scss";
import cn from "classnames";

const actionIcons = {
  edit: <EditOutlined />,
  delete: <DeleteOutlined />,
  visible: <EyeOutlined />,
  save: <SaveOutlined />,
};

export type ActionsProps = {
  actions: Array<
    ButtonProps & { actionType?: keyof typeof actionIcons; enabled?: boolean }
  >;
};

export const Actions = ({ actions }: ActionsProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap={8} justify="center">
      {actions.map((props, index) => {
        const { actionType, enabled = true, ...rest } = props;

        const button = (
          <Button
            icon={actionType ? actionIcons[actionType] : rest.icon}
            size="small"
            key={index}
            {...rest}
            onClick={actionType === "delete" ? undefined : rest.onClick}
            className={cn(rest.className, classes["btn"])}
          />
        );

        return (
          enabled &&
          (actionType === "delete" ? (
            <Popconfirm
              title={t("Ушбу амални тасдиқлайсизми?")}
              okText={t("Ҳа")}
              cancelText={t("Йўқ")}
              key={index}
              onConfirm={(e) => e && rest.onClick?.(e)}
            >
              {button}
            </Popconfirm>
          ) : (
            button
          ))
        );
      })}
    </Flex>
  );
};
