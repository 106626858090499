import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { DocNullable } from "hooks";

export type UseGetDocAllResponse = GeneralResponse<GeneralData<DocNullable>>;

export function useGetDocAll(props?: QueryParams<UseGetDocAllResponse>) {
  return useAPIQuery<UseGetDocAllResponse>({
    ...props,
    url: "/api/official-document/get-all",
    instance: generalRequest,
  });
}
