import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { ProjectNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetProjectByIdResponse = GeneralResponse<ProjectNullable>;

export function useGetProjectById(id?: number | string, props?: QueryParams<UseGetProjectByIdResponse>) {
  return useAPIQuery<UseGetProjectByIdResponse>({
    ...props,
    url: `/api/projects/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}