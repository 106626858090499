import { CloseOutlined } from "@ant-design/icons";
import { Flex, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FileList, FileUpload } from "./components";
import {
  FileFromGuid,
  ImageFromGuid,
  ImageFromGuidProps,
  TextWrap,
} from "components/shared";
import { useGetFileByGuid } from "hooks";
import { getFileNameFromHeaders, guidify } from "utils";
import * as yup from "yup";
import { MESSAGE_REQUIRED } from "constants/general";

export const fileResolver = yup.object().shape({
  guid: yup.string().required(MESSAGE_REQUIRED),
  name: yup.string().optional().nullable(),
});

export const fileListYup = yup.array().of(fileResolver);

export type FileTypes = "file" | "image";

export type FileProps = {
  file: FileType;
  handleDelete?: () => void;
  image?: ImageFromGuidProps;
  type: FileTypes;
};

export type FileType = yup.InferType<typeof fileResolver>;

export function File({ file, handleDelete, image, type }: FileProps) {
  const { t } = useTranslation();

  const fileGuid = guidify(file.guid);

  const query = useGetFileByGuid(fileGuid);
  const fileName = query.data?.headers && getFileNameFromHeaders(query.data?.headers);

  return (
    <Flex gap={4} align="center">
      {/* preview image or download file */}
      {type === "image" ? (
        <ImageFromGuid {...image} guid={fileGuid} mask />
      ) : type === "file" ? (
        <FileFromGuid guid={fileGuid} />
      ) : null}

      {/* file name */}
      <TextWrap value={fileName ?? file.name ?? ""} chars={18} />

      {/* handle file delete */}
      {handleDelete && (
        <Tooltip title={t("Ўчириш")}>
          <CloseOutlined
            style={{ fontSize: 20, color: "red", cursor: "pointer" }}
            onClick={handleDelete}
          />
        </Tooltip>
      )}
    </Flex>
  );
}

File.Upload = FileUpload;
File.List = FileList;

export default File;
