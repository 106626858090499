import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { DocNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetDocByIdResponse = GeneralResponse<DocNullable>;

export function useGetDocById(id?: number | string, props?: QueryParams<UseGetDocByIdResponse>) {
  return useAPIQuery<UseGetDocByIdResponse>({
    ...props,
    url: `/api/official-document/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}