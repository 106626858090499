import { ImageProps, Image, Skeleton } from "antd";
import { SkeletonImageProps } from "antd/es/skeleton/Image";
import AntdImage from "assets/images/svg/common/antd-image.svg";
import { Fragment } from "react";
import { Group } from "./components";
import { FILE_REPOSITORY_URL } from "constants/general";
import { useGetFileByGuid } from "hooks";

export type ImageFromGuidProps = {
  guid: string;
  size?: number;
  mask?: boolean;
  image?: Omit<ImageProps, "src">;
  skeleton?: SkeletonImageProps;
  loading?: boolean;
};

export function ImageFromGuid({
  guid,
  size = 24,
  image,
  skeleton,
  mask,
  loading,
}: ImageFromGuidProps) {
  const isGuidUrl = guid.startsWith("/") ;

  const query = useGetFileByGuid(guid, {
    options: {
      enabled: !(mask && isGuidUrl),
    },
  });
  const base64Blob = query.data?.data;
  const blob = base64Blob && new Blob([base64Blob], { type: base64Blob.type });
  const src = blob ? URL.createObjectURL(blob) : `${FILE_REPOSITORY_URL}${guid}`;

  const isLoading = (loading || query.isFetching) && !query.isRefetching;

  return (
    <Fragment>
      {isLoading && (
        <Skeleton.Image
          active
          {...skeleton}
          style={{ width: size, height: size, ...skeleton?.style }}
        />
      )}
      <Image
        alt={image?.alt}
        width={size}
        height={size}
        loading="lazy"
        {...image}
        preview={{
          mask: false,
          src,
          afterOpenChange: (open) => open && !query.data && !isGuidUrl && query.refetch(),
        }}
        src={mask ? AntdImage : src}
        style={{
          cursor: "pointer",
          objectFit: "contain",
          display: isLoading ? "none" : "block",
          ...image?.style,
        }}
      />
    </Fragment>
  );
}

ImageFromGuid.Group = Group;
