import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Cooperation } from "hooks";

export type UseCreateCooperationResponse = GeneralResponse<number>;

export type UseCreateCooperationVariables = Omit<Cooperation, "id" | "isVisible">;

export function useCreateCooperation(
  props?: MutationParams<UseCreateCooperationResponse, unknown, UseCreateCooperationVariables>
) {
  return useAPIMutation<UseCreateCooperationResponse, unknown, UseCreateCooperationVariables>({
    ...props,
    url: "/api/cooperation/create",
    instance: generalRequest,
    method: "POST",
  });
}
