import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { SupportNullable } from "hooks";

export type UseGetSupportAllResponse = GeneralResponse<GeneralData<SupportNullable>>;

export function useGetSupportAll(props?: QueryParams<UseGetSupportAllResponse>) {
  return useAPIQuery<UseGetSupportAllResponse>({
    ...props,
    url: "/api/support-entrepreneur/get-all",
    instance: generalRequest,
    key: ["get-support-all", props?.params]
  });
}
