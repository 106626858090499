import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeVideoReportVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangeVideoReportVisibleMainVariables = ChangeVisibleVariables;

export function useChangeVideoReportVisibleMain(
  props?: MutationParams<
    UseChangeVideoReportVisibleMainResponse,
    unknown,
    UseChangeVideoReportVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangeVideoReportVisibleMainResponse,
    unknown,
    UseChangeVideoReportVisibleMainVariables
  >({
    ...props,
    url: "/api/video/report/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
