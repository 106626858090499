import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Accreditation, UseCreateAccreditationVariables } from "hooks";

export type UseUpdateAccreditationResponse = GeneralResponse<number>;

export type UseUpdateAccreditationVariables = Pick<Accreditation, "id"> & UseCreateAccreditationVariables;

export function useUpdateAccreditation(
  props?: MutationParams<UseUpdateAccreditationResponse, unknown, UseUpdateAccreditationVariables>
) {
  return useAPIMutation<UseUpdateAccreditationResponse, unknown, UseUpdateAccreditationVariables>({
    ...props,
    url: "/api/accreditation/update",
    instance: generalRequest,
    method: "PUT",
  });
}
