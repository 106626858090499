import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeAccreditationVisibleResponse = GeneralResponse<boolean>;

export type UseChangeAccreditationVisibleVariables = ChangeVisibleVariables;

export function useChangeAccreditationVisible(
  props?: MutationParams<
    UseChangeAccreditationVisibleResponse,
    unknown,
    UseChangeAccreditationVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeAccreditationVisibleResponse,
    unknown,
    UseChangeAccreditationVisibleVariables
  >({
    ...props,
    url: "/api/accreditation/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
