import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Reglament } from "hooks";

export type UseCreateReglamentResponse = GeneralResponse<number>;

export type UseCreateReglamentVariables = Omit<Reglament, "id" | "ord" | "isVisible"> & Partial<Pick<Reglament, "ord">>;

export function useCreateReglament(
  props?: MutationParams<UseCreateReglamentResponse, unknown, UseCreateReglamentVariables>
) {
  return useAPIMutation<UseCreateReglamentResponse, unknown, UseCreateReglamentVariables>({
    ...props,
    url: "/api/reglament/create",
    instance: generalRequest,
    method: "POST",
  });
}
