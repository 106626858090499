import { useDownloadFile } from "hooks";
import { FileTextOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { generalRequest } from "services";
import { guidify } from "utils";

export type FileFromGuidProps = {
  guid: string;
  size?: number;
  loading?: boolean;
};

export function FileFromGuid({ guid, size = 20, loading }: FileFromGuidProps) {
  const { isDownloading, download } = useDownloadFile({
    url: "/api/files/download",
    instance: generalRequest,
  });

  const isLoading = loading || isDownloading;

  return (
    <Spin spinning={isLoading}>
      <FileTextOutlined
        onClick={() => download({ slug: guidify(guid) })}
        style={{ fontSize: size }}
      />
    </Spin>
  );
}
