import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteVacancyResponse = GeneralResponse<boolean>;

export type UseDeleteVacancyVariables = DeleteVariables;

export function useDeleteVacancy(
  props?: MutationParams<UseDeleteVacancyResponse, unknown, UseDeleteVacancyVariables>
) {
  return useAPIMutation<UseDeleteVacancyResponse, unknown, UseDeleteVacancyVariables>({
    ...props,
    url: "/api/vacancy/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
