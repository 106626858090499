import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteOfficialResponse = GeneralResponse<boolean>;

export type UseDeleteOfficialVariables = DeleteVariables;

export function useDeleteOfficial(
  props?: MutationParams<UseDeleteOfficialResponse, unknown, UseDeleteOfficialVariables>
) {
  return useAPIMutation<UseDeleteOfficialResponse, unknown, UseDeleteOfficialVariables>({
    ...props,
    url: "/api/official-person/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
