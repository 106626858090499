import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { CorruptionMaterialNullable } from "hooks";

export type UseGetCorruptionMaterialAllResponse = GeneralResponse<GeneralData<CorruptionMaterialNullable>>;

export function useGetCorruptionMaterialAll(props?: QueryParams<UseGetCorruptionMaterialAllResponse>) {
  return useAPIQuery<UseGetCorruptionMaterialAllResponse>({
    ...props,
    url: "/api/corruption-material/get-all",
    instance: generalRequest,
  });
}
