import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { SpeechNullable } from "hooks";

export type UseGetSpeechAllResponse = GeneralResponse<GeneralData<SpeechNullable>>;

export function useGetSpeechAll(props?: QueryParams<UseGetSpeechAllResponse>) {
  return useAPIQuery<UseGetSpeechAllResponse>({
    ...props,
    url: "/api/leaders-speech/get-all",
    instance: generalRequest,
    key: ["get-speech-all", props?.params]
  });
}
