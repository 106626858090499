import { ReactComponent as ArrowLeft } from "assets/images/svg/common/arrow-left.svg";
import { ReactComponent as Excel } from "assets/images/svg/common/excel.svg";
import { ReactComponent as LogoYT } from "assets/images/svg/common/logo-yt.svg";
import { ReactComponent as LogoSoliq } from "assets/images/svg/common/logo-soliq.svg";
import { ReactComponent as Error } from "assets/images/svg/common/error.svg";
import { ReactComponent as AntdImage } from "assets/images/svg/common/antd-image.svg";
import { ReactComponent as Telegram } from "assets/images/svg/common/tg.svg";

const icons = {
  ["arrow-left"]: ArrowLeft,
  ["excel"]: Excel,
  ["logo-yt"]: LogoYT,
  ["logo-soliq"]: LogoSoliq,
  ["error"]: Error,
  ["antd-image"]: AntdImage,
  ["tg"]: Telegram,
};

export type IconKeysType = keyof typeof icons;

export default icons;
