import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeSupportVisibleResponse = GeneralResponse<boolean>;

export type UseChangeSupportVisibleVariables = ChangeVisibleVariables;

export function useChangeSupportVisible(
  props?: MutationParams<
    UseChangeSupportVisibleResponse,
    unknown,
    UseChangeSupportVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeSupportVisibleResponse,
    unknown,
    UseChangeSupportVisibleVariables
  >({
    ...props,
    url: "/api/support-entrepreneur/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
