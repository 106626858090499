import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { SpeechNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetSpeechByIdResponse = GeneralResponse<SpeechNullable>;

export function useGetSpeechById(id?: number | string, props?: QueryParams<UseGetSpeechByIdResponse>) {
  return useAPIQuery<UseGetSpeechByIdResponse>({
    ...props,
    url: `/api/leaders-speech/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}