import { Avatar, Dropdown, Flex, MenuProps } from "antd";
import classes from "./Account.module.scss";
import { useGetUserMe } from "hooks";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Account = () => {
  const { t } = useTranslation();

  const getUserMeQuery = useGetUserMe({
    options: {
      enabled: !!localStorage.getItem("token")
    }
  });
  const me = getUserMeQuery.data?.data;

  const items: MenuProps["items"] = [
    {
      key: "logout",
      label: t("Чиқиш"),
      onClick: () => {
        localStorage.removeItem("token");
        window.location.reload();
      },
    },
  ];

  return (
    <Dropdown menu={{ items }} arrow>
      <Flex align="center" gap={8} className={classes["wrapper"]}>
        <Avatar
          icon={<UserOutlined />}
          style={{ backgroundColor: "#F0F7FF", color: "#325ecd" }}
        />
        <p>
          {me?.lastName} {me?.firstName} {me?.middleName}
        </p>
        <DownOutlined />
      </Flex>
    </Dropdown>
  );
};

export default Account;
