import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { ProjectNullable } from "hooks";

export type UseGetProjectAllResponse = GeneralResponse<GeneralData<ProjectNullable>>;

export function useGetProjectAll(props?: QueryParams<UseGetProjectAllResponse>) {
  return useAPIQuery<UseGetProjectAllResponse>({
    ...props,
    url: "/api/projects/get-all",
    instance: generalRequest,
    key: ["get-project-all", props?.params]
  });
}
