import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { BannerNullable } from "hooks";

export type UseGetBannerAllResponse = GeneralResponse<GeneralData<BannerNullable>>;

export function useGetBannerAll(props?: QueryParams<UseGetBannerAllResponse>) {
  return useAPIQuery<UseGetBannerAllResponse>({
    ...props,
    url: "/api/main-banner/get-all",
    instance: generalRequest,
  });
}
