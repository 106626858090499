import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import { BaseInputProps } from '../Input';
import '../Input.scss';
import { SizeType } from 'antd/es/config-provider/SizeContext';

interface Props extends BaseInputProps {
  id?: string;
  size: SizeType;
  disabled?: boolean;
}

const WrapperInput: FC<PropsWithChildren<Props>> = ({
  size = 'middle',
  label,
  children,
  id,
  disabled,
  wrapperClassName
}) => {
  return (
    <div
      className={cn(
        'sicnt-input-wrapper',
        `sicnt-input-wrapper__${size}`,
        disabled && 'sicnt-input-wrapper__disabled',
        wrapperClassName
      )}
    >
      {label && (
        <label htmlFor={id} className="sicnt-input-label">
          {label}
        </label>
      )}

      <div className="sicnt-input-children">{children}</div>
    </div>
  );
};

export default WrapperInput;
