import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { CorruptionFight, UseCreateCorruptionFightVariables } from "hooks";

export type UseUpdateCorruptionFightResponse = GeneralResponse<number>;

export type UseUpdateCorruptionFightVariables = Pick<CorruptionFight, "id"> & UseCreateCorruptionFightVariables;

export function useUpdateCorruptionFight(
  props?: MutationParams<UseUpdateCorruptionFightResponse, unknown, UseUpdateCorruptionFightVariables>
) {
  return useAPIMutation<UseUpdateCorruptionFightResponse, unknown, UseUpdateCorruptionFightVariables>({
    ...props,
    url: "/api/fighting-corruption/update",
    instance: generalRequest,
    method: "PUT",
  });
}
