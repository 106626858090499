import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Project, UseCreateProjectVariables } from "hooks";

export type UseUpdateProjectResponse = GeneralResponse<number>;

export type UseUpdateProjectVariables = Pick<Project, "id"> & UseCreateProjectVariables;

export function useUpdateProject(
  props?: MutationParams<UseUpdateProjectResponse, unknown, UseUpdateProjectVariables>
) {
  return useAPIMutation<UseUpdateProjectResponse, unknown, UseUpdateProjectVariables>({
    ...props,
    url: "/api/projects/update",
    instance: generalRequest,
    method: "PUT",
  });
}
