import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Cooperation, UseCreateCooperationVariables } from "hooks";

export type UseUpdateCooperationResponse = GeneralResponse<number>;

export type UseUpdateCooperationVariables = Pick<Cooperation, "id"> & UseCreateCooperationVariables;

export function useUpdateCooperation(
  props?: MutationParams<UseUpdateCooperationResponse, unknown, UseUpdateCooperationVariables>
) {
  return useAPIMutation<UseUpdateCooperationResponse, unknown, UseUpdateCooperationVariables>({
    ...props,
    url: "/api/cooperation/update",
    instance: generalRequest,
    method: "PUT",
  });
}
