import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeNewsVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangeNewsVisibleMainVariables = ChangeVisibleVariables;

export function useChangeNewsVisibleMain(
  props?: MutationParams<
    UseChangeNewsVisibleMainResponse,
    unknown,
    UseChangeNewsVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangeNewsVisibleMainResponse,
    unknown,
    UseChangeNewsVisibleMainVariables
  >({
    ...props,
    url: "/api/news/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
