import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { TenderNullable } from "hooks";

export type UseGetTenderAllResponse = GeneralResponse<GeneralData<TenderNullable>>;

export function useGetTenderAll(props?: QueryParams<UseGetTenderAllResponse>) {
  return useAPIQuery<UseGetTenderAllResponse>({
    ...props,
    url: "/api/tender/get-all",
    instance: generalRequest,
  });
}
