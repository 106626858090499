import { SelectProps } from "antd";
import { MenuType } from "hooks/api";
import { useTranslation } from "react-i18next";

export const useOptions = () => {
  const { t } = useTranslation();

  const officialPersonTypeOptions: SelectProps["options"] = [
    {
      label: t("Раҳбарият"),
      value: 1,
    },
    {
      label: t("Марказий аппарат"),
      value: 2,
    },
  ];

  const reglamentTypeOptions: SelectProps["options"] = [
    {
      label: t("Регламент"),
      value: 1,
    },
    {
      label: t("Мурожаат"),
      value: 2,
    },
    {
      label: t("Коррупция мурожаат"),
      value: 3,
    },
  ];

  const docOpenTypeOptions: SelectProps["options"] = [
    {
      label: t("Файл"),
      value: 1,
    },
    {
      label: t("Сайт орқали"),
      value: 2,
    },
  ];

  const corruptionMaterialTypeOptions: SelectProps["options"] = [
    {
      label: t("Тарқатма"),
      value: 1,
    },
    {
      label: t("Видео"),
      value: 2,
    },
    {
      label: t("Ўқув"),
      value: 3,
    },
  ];

  const corruptionFightTypeOptions: SelectProps["options"] = [
    {
      label: t("Хужжатлар"),
      value: 1,
    },
    {
      label: t("Амалга оширилган ишлар"),
      value: 2,
    },
  ];

  const appealTypeOptions: SelectProps["options"] = [
    {
      label: t("Коррупция"),
      value: 1,
    },
    {
      label: t("Ариза"),
      value: 2,
    },
    {
      label: t("Шикоят"),
      value: 3,
    },
    {
      label: t("Таклиф"),
      value: 4,
    },
  ];

  const cooperationTypeOptions: SelectProps["options"] = [
    {
      label: t("Идоралараро"),
      value: 1,
    },
    {
      label: t("Халқаро"),
      value: 2,
    },
  ];

  const menuTypeOptions: SelectProps["options"] = [
    {
      label: t("Ички"),
      value: "INNER" as MenuType,
    },
    {
      label: t("Ташқи"),
      value: "OUTER" as MenuType,
    },
  ];

  return {
    officialPersonTypeOptions,
    reglamentTypeOptions,
    docOpenTypeOptions,
    corruptionMaterialTypeOptions,
    appealTypeOptions,
    cooperationTypeOptions,
    corruptionFightTypeOptions,
    menuTypeOptions
  };
};
