import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeMenuVisibleResponse = GeneralResponse<boolean>;

export type UseChangeMenuVisibleVariables = ChangeVisibleVariables;

export function useChangeMenuVisible(
  props?: MutationParams<
    UseChangeMenuVisibleResponse,
    unknown,
    UseChangeMenuVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeMenuVisibleResponse,
    unknown,
    UseChangeMenuVisibleVariables
  >({
    ...props,
    url: "/api/menu/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
