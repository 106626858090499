import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { PhotoReport, UseCreatePhotoReportVariables } from "hooks";

export type UseUpdatePhotoReportResponse = GeneralResponse<number>;

export type UseUpdatePhotoReportVariables = Pick<PhotoReport, "id"> & UseCreatePhotoReportVariables;

export function useUpdatePhotoReport(
  props?: MutationParams<UseUpdatePhotoReportResponse, unknown, UseUpdatePhotoReportVariables>
) {
  return useAPIMutation<UseUpdatePhotoReportResponse, unknown, UseUpdatePhotoReportVariables>({
    ...props,
    url: "/api/photo/report/update",
    instance: generalRequest,
    method: "PUT",
  });
}
