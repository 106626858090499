import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Banner, UseCreateBannerVariables } from "hooks";

export type UseUpdateBannerResponse = GeneralResponse<number>;

export type UseUpdateBannerVariables = Pick<Banner, "id"> & UseCreateBannerVariables;

export function useUpdateBanner(
  props?: MutationParams<UseUpdateBannerResponse, unknown, UseUpdateBannerVariables>
) {
  return useAPIMutation<UseUpdateBannerResponse, unknown, UseUpdateBannerVariables>({
    ...props,
    url: "/api/main-banner/update",
    instance: generalRequest,
    method: "PUT",
  });
}
