import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { UseCreateUsefulVariables, Useful } from "hooks";

export type UseUpdateUsefulResponse = GeneralResponse<number>;

export type UseUpdateUsefulVariables = Pick<Useful, "id"> & UseCreateUsefulVariables;

export function useUpdateUseful(
  props?: MutationParams<UseUpdateUsefulResponse, unknown, UseUpdateUsefulVariables>
) {
  return useAPIMutation<UseUpdateUsefulResponse, unknown, UseUpdateUsefulVariables>({
    ...props,
    url: "/api/useful-page/update",
    instance: generalRequest,
    method: "PUT",
  });
}
