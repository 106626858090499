import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangePhotoReportVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangePhotoReportVisibleMainVariables = ChangeVisibleVariables;

export function useChangePhotoReportVisibleMain(
  props?: MutationParams<
    UseChangePhotoReportVisibleMainResponse,
    unknown,
    UseChangePhotoReportVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangePhotoReportVisibleMainResponse,
    unknown,
    UseChangePhotoReportVisibleMainVariables
  >({
    ...props,
    url: "/api/photo/report/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
