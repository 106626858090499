import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { PositionNullable } from "hooks";

export type UseGetPositionAllResponse = GeneralResponse<GeneralData<PositionNullable>>;

export function useGetPositionAll(props?: QueryParams<UseGetPositionAllResponse>) {
  return useAPIQuery<UseGetPositionAllResponse>({
    ...props,
    url: "/api/sp-position/get-all",
    instance: generalRequest,
  });
}
