import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeEventVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangeEventVisibleMainVariables = ChangeVisibleVariables;

export function useChangeEventVisibleMain(
  props?: MutationParams<
    UseChangeEventVisibleMainResponse,
    unknown,
    UseChangeEventVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangeEventVisibleMainResponse,
    unknown,
    UseChangeEventVisibleMainVariables
  >({
    ...props,
    url: "/api/public-event/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
