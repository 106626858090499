import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteCooperationResponse = GeneralResponse<boolean>;

export type UseDeleteCooperationVariables = DeleteVariables;

export function useDeleteCooperation(
  props?: MutationParams<UseDeleteCooperationResponse, unknown, UseDeleteCooperationVariables>
) {
  return useAPIMutation<UseDeleteCooperationResponse, unknown, UseDeleteCooperationVariables>({
    ...props,
    url: "/api/cooperation/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
