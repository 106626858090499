import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import { Image } from "antd";
import axios, { AxiosResponse } from "axios";
import { ImageFromGuid, ImageFromGuidProps } from "components/shared/ImageFromGuid";
import { FILE_REPOSITORY_URL } from "constants/general";
import { generalRequest } from "services";

export type GroupProps = {
  guids: string[];
  props?: Omit<ImageFromGuidProps, "guid">;
};

export const Group = ({ guids, props }: GroupProps) => {
  const isAllGuidsUrl = guids.every((guid) => guid.startsWith("/") );

  const queries = useQueries<Array<UseQueryOptions<AxiosResponse<Blob>>>>({
    queries: guids.map((guid) => {
      const enabled = !(props?.mask && isAllGuidsUrl);
      const isGuidUrl = guid.startsWith("/") ;
      const key = isGuidUrl
        ? `${FILE_REPOSITORY_URL}${guid}`
        : `/api/files/download/${guid}`;

      if (isGuidUrl) {
        return {
          queryKey: [key],
          queryFn: () => axios.get(key, { responseType: "blob" }),
          enabled,
        };
      } else {
        return {
          queryKey: [key],
          queryFn: () => generalRequest.get(key, { responseType: "blob" }),
          enabled,
        };
      }
    }),
  });

  const items = isAllGuidsUrl
    ? guids.map((guid) => `${FILE_REPOSITORY_URL}${guid}`)
    : queries
        .map((query) => {
          const base64Blob = query.data?.data;
          const blob = base64Blob && new Blob([base64Blob], { type: base64Blob.type });
          const src = blob && URL.createObjectURL(blob);

          return src ?? "";
        })
        .filter((item) => !!item);

  return (
    <Image.PreviewGroup
      items={items}
      preview={{
        afterOpenChange: (open) =>
          open &&
          queries.some((query) => !query.data) &&
          queries.map((query) => query.refetch()),
      }}
    >
      <ImageFromGuid {...props} guid={guids[0]} />
    </Image.PreviewGroup>
  );
};
