import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { UseCreateVacancyVariables, Vacancy } from "hooks";

export type UseUpdateVacancyResponse = GeneralResponse<number>;

export type UseUpdateVacancyVariables = Pick<Vacancy, "id"> & UseCreateVacancyVariables;

export function useUpdateVacancy(
  props?: MutationParams<UseUpdateVacancyResponse, unknown, UseUpdateVacancyVariables>
) {
  return useAPIMutation<UseUpdateVacancyResponse, unknown, UseUpdateVacancyVariables>({
    ...props,
    url: "/api/vacancy/update",
    instance: generalRequest,
    method: "PUT",
  });
}
