import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary, PageLoader } from "components/shared";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "services/api";
import { Provider } from "react-redux";
import store, { persistor } from "store";
import { I18nextProvider } from "react-i18next";
import i18n from "services/i18n";
import { AuthProvider } from "context/auth";

// dayjs
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData"
dayjs.extend(localeData)
dayjs.extend(weekday);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PersistGate persistor={persistor} loading={<PageLoader />}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </PersistGate>
            </Provider>
          </QueryClientProvider>
        </I18nextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
