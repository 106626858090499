import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeCorruptionFightVisibleResponse = GeneralResponse<boolean>;

export type UseChangeCorruptionFightVisibleVariables = ChangeVisibleVariables;

export function useChangeCorruptionFightVisible(
  props?: MutationParams<
    UseChangeCorruptionFightVisibleResponse,
    unknown,
    UseChangeCorruptionFightVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeCorruptionFightVisibleResponse,
    unknown,
    UseChangeCorruptionFightVisibleVariables
  >({
    ...props,
    url: "/api/fighting-corruption/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
