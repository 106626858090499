import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { StructureNullable } from "hooks";

export type UseGetStructureAllResponse = GeneralResponse<GeneralData<StructureNullable>>;

export function useGetStructureAll(props?: QueryParams<UseGetStructureAllResponse>) {
  return useAPIQuery<UseGetStructureAllResponse>({
    ...props,
    url: "/api/center-structure/get-all",
    instance: generalRequest,
    key: ["get-structure-all", props?.params]
  });
}
