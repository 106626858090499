import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeProjectVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangeProjectVisibleMainVariables = ChangeVisibleVariables;

export function useChangeProjectVisibleMain(
  props?: MutationParams<
    UseChangeProjectVisibleMainResponse,
    unknown,
    UseChangeProjectVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangeProjectVisibleMainResponse,
    unknown,
    UseChangeProjectVisibleMainVariables
  >({
    ...props,
    url: "/api/projects/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
