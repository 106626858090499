import { SVGProps, useMemo } from "react";
import { createProxyWithHandler } from "utils";
import icons, { IconKeysType } from "assets/images/svg";

export interface ISvgProps extends SVGProps<SVGSVGElement> {
  icon: IconKeysType;
}

export function Svg({ icon, ...props }: ISvgProps) {
  const SVG_LIST: Record<IconKeysType, JSX.Element> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(icons).map(([name, Icon]) => [name, <Icon name={name} {...props} />])
      ) as Record<IconKeysType, JSX.Element>,
    [props]
  );

  const proxySvgList = createProxyWithHandler(SVG_LIST, <span />);

  return proxySvgList[icon];
}
