import { Tooltip, TooltipProps } from "antd";
import cn from "classnames";
import { HTMLAttributes } from "react";
import classes from "./TextWrap.module.scss";

export function subString(str: string, n: number) {
	return str ? (str.length > n ? str.slice(0, n - 1).trim() + "..." : str) : "";
}

export type TextWrapProps = {
	value: string;
	defaultValue?: string;
	chars?: number;
	clamp?: boolean | number;
	popover?: boolean;
	wrapper?: HTMLAttributes<HTMLParagraphElement>;
	tooltip?: TooltipProps;
};

export const TextWrap = ({ value = "", defaultValue = "", chars = 0, clamp = true, popover = true, wrapper, tooltip }: TextWrapProps) => {
	if (typeof value !== "string") {
		return null;
	}

	const showTooltip = value && (chars ? value.length > chars : clamp);

	const text = value || defaultValue;

	const className = cn(classes["text"], clamp && (clamp === 2 ? classes["line-clamp-2"] : classes["line-clamp-1"]), wrapper?.className);

	return (
		<Tooltip placement="top" title={popover && showTooltip ? text : null} {...tooltip}>
			<p {...wrapper} className={className}>
				{chars ? subString(text, chars) : text}
			</p>
		</Tooltip>
	);
};
