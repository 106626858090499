import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangePartnerVisibleResponse = GeneralResponse<boolean>;

export type UseChangePartnerVisibleVariables = ChangeVisibleVariables;

export function useChangePartnerVisible(
  props?: MutationParams<
    UseChangePartnerVisibleResponse,
    unknown,
    UseChangePartnerVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangePartnerVisibleResponse,
    unknown,
    UseChangePartnerVisibleVariables
  >({
    ...props,
    url: "/api/cooperation-item/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
