import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";

export function useUploadFile(props?: MutationParams<string, unknown, FormData>) {
  return useAPIMutation<string, unknown, FormData>({
    ...props,
    url: "/api/files/upload",
    instance: generalRequest,
    method: "POST",
  });
}
