import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeletePageResponse = GeneralResponse<boolean>;

export type UseDeletePageVariables = DeleteVariables;

export function useDeletePage(
  props?: MutationParams<UseDeletePageResponse, unknown, UseDeletePageVariables>
) {
  return useAPIMutation<UseDeletePageResponse, unknown, UseDeletePageVariables>({
    ...props,
    url: "/api/page/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
