import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { PartnerNullable } from "hooks";

export type UseGetPartnerAllResponse = GeneralResponse<GeneralData<PartnerNullable>>;

export function useGetPartnerAll(props?: QueryParams<UseGetPartnerAllResponse>) {
  return useAPIQuery<UseGetPartnerAllResponse>({
    ...props,
    url: "/api/cooperation-item/get-all",
    instance: generalRequest,
    key: ["get-partner-all", props?.params]
  });
}
