import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Support, UseCreateSupportVariables } from "hooks";

export type UseUpdateSupportResponse = GeneralResponse<number>;

export type UseUpdateSupportVariables = Pick<Support, "id"> & UseCreateSupportVariables;

export function useUpdateSupport(
  props?: MutationParams<UseUpdateSupportResponse, unknown, UseUpdateSupportVariables>
) {
  return useAPIMutation<UseUpdateSupportResponse, unknown, UseUpdateSupportVariables>({
    ...props,
    url: "/api/support-entrepreneur/update",
    instance: generalRequest,
    method: "PUT",
  });
}
