import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteEventResponse = GeneralResponse<boolean>;

export type UseDeleteEventVariables = DeleteVariables;

export function useDeleteEvent(
  props?: MutationParams<UseDeleteEventResponse, unknown, UseDeleteEventVariables>
) {
  return useAPIMutation<UseDeleteEventResponse, unknown, UseDeleteEventVariables>({
    ...props,
    url: "/api/public-event/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
