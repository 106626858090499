import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import translationLat from 'assets/locales/lat/translation.json';
import translationRu from 'assets/locales/ru/translation.json';
import translationEn from 'assets/locales/en/translation.json';
import { languages } from 'config';

// https://www.i18next.com/overview/configuration-options

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    keySeparator: '|',
    resources: {
      lat: {
        translation: translationLat,
      },
      ru: {
        translation: translationRu,
      },
      en: {
        translation: translationEn,
      },
    },
    fallbackLng: languages,
    detection: {
      order: ['localStorage'],
    },
    react: {
      useSuspense: false,
    },
    supportedLngs: languages,
  });

export default i18n;
