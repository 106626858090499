import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { TenderNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetTenderByIdResponse = GeneralResponse<TenderNullable>;

export function useGetTenderById(id?: number | string, props?: QueryParams<UseGetTenderByIdResponse>) {
  return useAPIQuery<UseGetTenderByIdResponse>({
    ...props,
    url: `/api/tender/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}