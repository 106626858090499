import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";

export type UseAuthLoginResponse = {
  token: string;
};

export type UseAuthLoginVariables = {
  userName: string;
  password: string;
};

export function useAuthLogin(
  props?: MutationParams<UseAuthLoginResponse, unknown, UseAuthLoginVariables>
) {
  return useAPIMutation<UseAuthLoginResponse, unknown, UseAuthLoginVariables>({
    ...props,
    url: "/api/auth/login",
    instance: generalRequest,
    method: "POST",
  });
}
