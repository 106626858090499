import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Vacancy } from "hooks";

export type UseCreateVacancyResponse = GeneralResponse<number>;

export type UseCreateVacancyVariables = Omit<
  Vacancy,
  "id" | "isVisible" | "position" | "salary"
> &
  Partial<Pick<Vacancy, "salary">>;

export function useCreateVacancy(
  props?: MutationParams<UseCreateVacancyResponse, unknown, UseCreateVacancyVariables>
) {
  return useAPIMutation<UseCreateVacancyResponse, unknown, UseCreateVacancyVariables>({
    ...props,
    url: "/api/vacancy/create",
    instance: generalRequest,
    method: "POST",
  });
}
