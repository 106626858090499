import {
  ComponentProps,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
} from "react";
import {
  Table as AntTable,
  TableProps as AntTableProps,
  TableColumnGroupType,
  TableColumnProps,
  TableColumnType,
  TableColumnsType,
  TablePaginationConfig,
} from "antd";
import cn from "classnames";
import { isArray } from "utils";
import Download from "./_components/Download";
import { useItemRender } from "../Pagination";
import "./Table.scss";

export interface TableProps<RecordType extends object = any>
  extends AntTableProps<RecordType> {
  download?: ComponentProps<typeof Download>;
}

export const Table = forwardRef(
  <TRecordType extends object = any>(
    {
      dataSource,
      size,
      pagination,
      download,
      bordered = true,
      ...props
    }: TableProps<TRecordType>,
    ref: any
  ) => {
    const { itemRender } = useItemRender();

    return (
      <div className={cn("sicnt-table")}>
        <AntTable<TRecordType>
          {...props}
          bordered={bordered}
          ref={ref}
          size={size}
          dataSource={isArray(dataSource) ? dataSource : []}
          pagination={
            pagination && {
              size: size !== "small" ? "default" : "small",
              itemRender,
              ...pagination,
              position: ["bottomLeft"],
              className: "sicnt-pagination",
            }
          }
        />
        {download?.show && !!dataSource?.length && <Download {...download} />}
      </div>
    );
  }
) as CompoundedComponent;

const {
  Column,
  ColumnGroup,
  EXPAND_COLUMN,
  SELECTION_ALL,
  SELECTION_COLUMN,
  SELECTION_INVERT,
  SELECTION_NONE,
  Summary,
} = AntTable;

interface CompoundedComponent
  extends ForwardRefExoticComponent<TableProps & RefAttributes<HTMLDivElement>> {
  Column: typeof Column;
  ColumnGroup: typeof ColumnGroup;
  EXPAND_COLUMN: typeof EXPAND_COLUMN;
  SELECTION_ALL: typeof SELECTION_ALL;
  SELECTION_COLUMN: typeof SELECTION_COLUMN;
  SELECTION_INVERT: typeof SELECTION_INVERT;
  SELECTION_NONE: typeof SELECTION_NONE;
  Summary: typeof Summary;
}

Table.Summary = Summary;
Table.Column = Column;
Table.ColumnGroup = ColumnGroup;
Table.EXPAND_COLUMN = EXPAND_COLUMN;
Table.SELECTION_ALL = SELECTION_ALL;
Table.SELECTION_COLUMN = SELECTION_COLUMN;
Table.SELECTION_INVERT = SELECTION_INVERT;
Table.SELECTION_NONE = SELECTION_NONE;

export {
  type TableColumnGroupType,
  type TableColumnProps,
  type TableColumnType,
  type TableColumnsType,
  type TablePaginationConfig,
};
