import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Event } from "hooks";

export type UseCreateEventResponse = GeneralResponse<number>;

export type UseCreateEventVariables = Omit<
  Event,
  "id" | "isVisible" | "isVisibleMain" | "publishDate" | "readCount" | "budget" | "workDone" | "exportVolume" | "newJobs"
> &
  Partial<Pick<Event, "publishDate" | "budget" | "workDone" | "exportVolume" | "newJobs">>;

export function useCreateEvent(
  props?: MutationParams<UseCreateEventResponse, unknown, UseCreateEventVariables>
) {
  return useAPIMutation<UseCreateEventResponse, unknown, UseCreateEventVariables>({
    ...props,
    url: "/api/public-event/create",
    instance: generalRequest,
    method: "POST",
  });
}
