import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeDocVisibleResponse = GeneralResponse<boolean>;

export type UseChangeDocVisibleVariables = ChangeVisibleVariables;

export function useChangeDocVisible(
  props?: MutationParams<
    UseChangeDocVisibleResponse,
    unknown,
    UseChangeDocVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeDocVisibleResponse,
    unknown,
    UseChangeDocVisibleVariables
  >({
    ...props,
    url: "/api/official-document/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
