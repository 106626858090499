import { createContext } from "react";

import useValue from "./useValue";

export type AuthContextType = ReturnType<typeof useValue>;

const AuthContext = createContext<AuthContextType>(
	{} as AuthContextType
);

export default AuthContext;
