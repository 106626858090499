import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangePartnerVisibleMainResponse = GeneralResponse<boolean>;

export type UseChangePartnerVisibleMainVariables = ChangeVisibleVariables;

export function useChangePartnerVisibleMain(
  props?: MutationParams<
    UseChangePartnerVisibleMainResponse,
    unknown,
    UseChangePartnerVisibleMainVariables
  >
) {
  return useAPIMutation<
    UseChangePartnerVisibleMainResponse,
    unknown,
    UseChangePartnerVisibleMainVariables
  >({
    ...props,
    url: "/api/cooperation-item/change-is-visible-main",
    instance: generalRequest,
    method: "PUT",
  });
}
